<script lang="ts">
export default defineComponent({
    name: 'ArticleH2Component',
    props: {
        blok: {
            type: Object as PropType<ArticleH2BlokInterface>,
            required: true,
        },
        stickyZipCta: { type: Boolean, default: false },
    },
    computed: {
        anchorTag() {
            const anchorText = this.blok.anchorTag || this.blok.text;
            return anchorTagFormat(anchorText);
        },
    },
});
</script>

<template>
    <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
    <div
        :id="anchorTag"
        class="ArticleH2 pt-lg-450 mt-200 mt-lg-n450"
        :class="{ 'sticky-cta': stickyZipCta }">
        <h2
            v-editable="blok"
            class="mt-lg-100 pt-100">
            <a :href="`#${anchorTag}`">{{ blok.text }}</a>
        </h2>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/variables';
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';
@use '~/assets/scss/constants';

.ArticleH2 {
    // offset to account for sticky navbar and cta
    @include breakpoints.media-breakpoint-up(lg) {
        &.sticky-cta {
            margin-top: -(constants.$sticky-navbar-plus-cta-height) !important;
            padding-top: constants.$sticky-navbar-plus-cta-height !important;
        }
    }

    a {
        color: unset;
    }
}
</style>
